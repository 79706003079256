import env from "env-sanitize"
import { config } from "dotenv"

const isStaging = env("STAGING", x => x.asBoolean(), false)
const environment = process.env.NODE_ENV

if (isStaging) {
  config({ path: `.env.${environment}.staging` })
} else {
  config({ path: `.env.${environment}` })
}

const appConfig = {
  isStaging: isStaging,
  contentful: {
    deliveryAccessToken: process.env.CONTENTFUL_DELIVERY_ACCESS_TOKEN ?? "",
    previewAccessToken: process.env.CONTENTFUL_PREVIEW_ACCESS_TOKEN,
    host: process.env.CONTENTFUL_HOST,
    spaceId: process.env.CONTENTFUL_SPACE_ID ?? "",
  },
  metadata: {
    siteUrl: process.env.GATSBY_SITE_URL ?? "",
  },
  azure: {
    apimGatewayUrl: process.env.GATSBY_AZURE_APIM_BASE_URL ?? "",
    apimSubscriptionKey: process.env.GATSBY_AZURE_APIM_SUBSCRIPTION_KEY ?? "",
    sharedMppCloudBaseUrl: process.env.GATSBY_SHARED_MPP_CLOUD_BASE_URL ?? "",
    sharedMppCloudSubscriptionKey:
      process.env.GATSBY_SHARED_MPP_CLOUD_SUBSCRIPTION_KEY ?? "",
  },
  close: {
    statusIdForInterested: "stat_VzO8IXHMSxo6KhQu0Jvx9wNBUAarUlKU1fYvfHtpcoH",
    statusIdForQualified: "stat_7hsb81daD3SZIdtRNjqJSksX407KkZSEQGupA5nQBKX",
  },
  recaptcha: {
    siteKey: process.env.GATSBY_RECAPTCHA_SITE_KEY ?? "",
  },
  sendgrid: {
    apiKey: process.env.GATSBY_SENDGRID_API_KEY ?? "",
    emailOtpTemplateId: process.env.GATSBY_SENDGRID_EMAIL_OTP_TEMPLATE_ID,
  },
  firebaseConfig: {
    apiKey: process.env.GATSBY_FIREBASE_API_KEY,
    authDomain: process.env.GATSBY_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.GATSBY_FIREBASE_PROJECT_ID,
    storageBucket: process.env.GATSBY_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.GATSBY_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.GATSBY_FIREBASE_APP_ID,
    measurementId: process.env.GATSBY_FIREBASE_MEASUREMENT_ID,
  },
}

export default appConfig
