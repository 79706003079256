import { AxiosInstance } from "axios"
import cfg from "../../core/utils/config"

const npgasaIdCustomField = "cf_bAXyjC9By5ie2qBQpVDLctDtidonY0zsf7Rnx8VM50Y"
const referralSourceCustomField =
  "cf_PHMtgFtDT4KkNmmfcsaVcVv69ymeBk8TkLuVWHu0QPY"
const parlorTeamsCustomField = "cf_HO2417yJeS5CG6EEMJqNTSukKq1bPYqfK7ZvRhLWbSg"
const moibleTeamsCustomField = "cf_gy6ycWUday8Cm3nGT8itSLecDfvQzm4zAooccQ0eXsH"

export type LeadContactType = "office"
export type LeadStatusType = "interested" | "qualified"
export type LeadContactAddressLabel = "business"

export type LeadOpportunityData = {
  note?: string
  statusId: string
  valuePeriod: string
  value: number
  confidence: number
}

export type LeadAddressData = {
  label: LeadContactAddressLabel
  address_1: string
  address_2?: string
  city?: string
  stateOrProvince?: string
  zipOrPostalCode?: string
  country: string
}

export type LeadContactEmailData = {
  type: LeadContactType
  email: string
}

export type LeadContactPhoneData = {
  type: LeadContactType
  phone: string
}

export type LeadCustomFields = {
  npgasaId?: string
  referralSource?: string
  includeCustomerApp?: boolean
  numOfParlorTeams?: number
  numOfMobileTeams?: number
}

export type LeadContactData = {
  name: string
  firstName: string
  lastName: string
  title: string
  emails: LeadContactEmailData[]
  phones: LeadContactPhoneData[]
}

export type LeadData = {
  name: string
  statusId: string
  url?: string
  opportunity?: LeadOpportunityData
  contacts: LeadContactData[]
  addresses: LeadAddressData[]
}

export class LeadGenerationApi {
  private client: AxiosInstance

  constructor(client: AxiosInstance) {
    this.client = client
  }

  execute = (
    data: LeadData,
    statusType: LeadStatusType,
    customFields?: LeadCustomFields
  ) => {
    let leadData: any = data
    let statusId: string
    switch (statusType) {
      case "qualified":
        statusId = cfg.close.statusIdForQualified
        break
      case "interested":
      default:
        statusId = cfg.close.statusIdForInterested
        break
    }

    if (customFields) {
      const { npgasaId, referralSource, numOfMobileTeams, numOfParlorTeams } =
        customFields
      if (npgasaId) {
        leadData = {
          ...leadData,
          custom: {
            ...leadData.custom,
            [npgasaIdCustomField]: npgasaId,
          },
        }
      }
      if (referralSource) {
        leadData = {
          ...leadData,
          custom: {
            ...leadData.custom,
            [referralSourceCustomField]: referralSource,
          },
        }
      }
      if (npgasaId) {
        leadData = {
          ...leadData,
          custom: {
            ...leadData.custom,
            [npgasaIdCustomField]: npgasaId,
          },
        }
      }
      if (numOfMobileTeams) {
        leadData = {
          ...leadData,
          custom: {
            ...leadData.custom,
            [moibleTeamsCustomField]: numOfMobileTeams,
          },
        }
      }
      if (numOfParlorTeams) {
        leadData = {
          ...leadData,
          custom: {
            ...leadData.custom,
            [parlorTeamsCustomField]: numOfParlorTeams,
          },
        }
      }
    }
    // console.log({
    //   ...leadData,
    //   statusId,
    // })
    return this.client.post(
      "shared/mba/v2/crm/close/leads",
      {
        ...leadData,
        statusId,
      },
      {
        headers: {
          Authorization: "Bearer abc123",
        },
      }
    )
  }
}
