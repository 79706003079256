import { FirebaseApp } from "firebase/app"
import {
  applyActionCode,
  Auth,
  createUserWithEmailAndPassword,
  EmailAuthProvider,
  fetchSignInMethodsForEmail,
  getAuth,
  GoogleAuthProvider,
  isSignInWithEmailLink,
  sendEmailVerification,
  sendPasswordResetEmail,
  sendSignInLinkToEmail,
  signInWithEmailAndPassword,
  signInWithEmailLink,
  signInWithPopup,
  updatePassword,
  updateProfile,
} from "firebase/auth"
import {
  Firestore,
  getFirestore,
  getDoc,
  doc,
  collection,
} from "firebase/firestore"
import { isBrowser } from "../utils/browser"
import config from "../utils/config"

class Firebase {
  // Firebase APIs
  auth: Auth
  db: Firestore

  // Helper
  emailAuthProvider: EmailAuthProvider

  // Social Sign In Method Providers
  googleProvider: GoogleAuthProvider

  constructor(app: FirebaseApp) {
    this.auth = getAuth(app)
    this.db = getFirestore(app)
    this.emailAuthProvider = new EmailAuthProvider()
    this.googleProvider = new GoogleAuthProvider()
  }

  // --- Authentication API ---

  doCreateUserWithEmailAndPassword = async (
    firstName: string,
    lastName: string,
    email: string,
    password: string
  ) => {
    const credentials = await createUserWithEmailAndPassword(
      this.auth,
      email,
      password
    )
    await updateProfile(credentials.user, {
      displayName: `${firstName} ${lastName}`,
    })
    return credentials
  }

  doSignInWithEmailAndPassword = async (email: string, password: string) =>
    signInWithEmailAndPassword(this.auth, email, password)

  doVerifyIsNewUser = async (email: string) => {
    const result = await fetchSignInMethodsForEmail(this.auth, email)
    // If there is zero sign in methods, then this could be a new user.
    return result.length === 0
  }

  doSendSignInLinkToEmail = async (
    email: string,
    redirectUrl: string,
    firstName?: string,
    lastName?: string
  ) => {
    const actionCodeSettings = {
      url: `${config.metadata.siteUrl}/auth/signup?email=${email}&redirectUrl=${redirectUrl}&firstName=${firstName}&lastName=${lastName}`,
      handleCodeInApp: true,
    }
    await sendSignInLinkToEmail(this.auth, email, actionCodeSettings)
    // The link was successfully sent. Inform the user.
    // Save the email locally so you don't need to ask the user for it again
    // if they open the link on the same device.
    if (isBrowser()) {
      window.localStorage.setItem("emailForSignIn", email)
    }
  }

  doConfirmSignInLink = async (
    email: string,
    emailLink: string,
    firstName?: string,
    lastName?: string
  ) => {
    // Confirm the link is a sign-in with email link.
    if (isSignInWithEmailLink(this.auth, emailLink)) {
      // The client SDK will parse the code from the link for you.
      if (email) {
        const credentials = await signInWithEmailLink(
          this.auth,
          email,
          emailLink
        )
        // Clear email from storage.
        if (isBrowser()) {
          window.localStorage.removeItem("emailForSignIn")
        }

        if (firstName && lastName) {
          await updateProfile(credentials.user, {
            displayName: `${firstName} ${lastName}`,
          })
        }

        return credentials
      }
    }
  }

  doSignInWithGoogle = async () =>
    signInWithPopup(this.auth, this.googleProvider)

  doSignOut = async () => this.auth.signOut()

  doPasswordReset = async (email: string) => {
    const actionCodeSettings = {
      url: `${config.metadata.siteUrl}/auth/change-password?email=${email}`,
    }
    return sendPasswordResetEmail(this.auth, email, actionCodeSettings)
  }

  doSendEmailVerification = async () => {
    const { currentUser } = this.auth
    if (currentUser) {
      const actionCodeSettings = {
        url: `${config.metadata.siteUrl}/auth/verify-email?email=${currentUser.email}`,
      }
      return sendEmailVerification(currentUser, actionCodeSettings)
    } else {
      return null
    }
  }

  doVerifyActionCode = async (oobCode: string) =>
    applyActionCode(this.auth, oobCode)

  doPasswordUpdate = async (password: string) =>
    this.auth.currentUser
      ? updatePassword(this.auth.currentUser, password)
      : null

  // *** Merge Auth and DB User API *** //

  onAuthUserListener = (next: any, fallback: any) =>
    this.auth.onAuthStateChanged(authUser => {
      if (authUser) {
        this.user(authUser.uid).then(snapshot => {
          next(
            snapshot.exists()
              ? {
                  ...authUser,
                  ...snapshot.data(),
                }
              : authUser
          )
        })
      } else {
        fallback()
      }
    })

  // --- User API ---
  userDoc = (uid: string) => doc(this.db, "users", uid)
  user = (uid: string) => getDoc(this.userDoc(uid))
  users = () => collection(this.db, "users")

  // --- Message API ---
  messageDoc = (uid: string) => doc(this.db, "messages", uid)
  message = (uid: string) => getDoc(this.messageDoc(uid))
  messages = () => collection(this.db, "messages")
}

export default Firebase
