import * as React from "react"
import {
  getAzureApiManagementClient,
  getSharedMppCloudClient,
} from "../../../api/client"
import { ContactFormApi } from "../../../api/services/contactForm"
import { OtpApi } from "../../../api/services/otp"
import { SendEmailApi } from "../../../api/services/sendEmail"
import { VerifyReCaptchaApi } from "../../../api/services/verifyReCaptcha"
import { LeadGenerationApi } from "../../../api/services/generateLead"

type ApiProviderProps = { children: React.ReactNode }
export interface ApiContextInterface {
  sendEmailApi: SendEmailApi
  verifyReCaptchaApi: VerifyReCaptchaApi
  contactFormApi: ContactFormApi
  leadGenerationApi: LeadGenerationApi
  otpApi: OtpApi
}
const ApiContext = React.createContext<ApiContextInterface | null>(null)

const ApiProvider = ({ children }: ApiProviderProps) => {
  const azureApimClient = getAzureApiManagementClient()
  const mppCloudClient = getSharedMppCloudClient()
  const sendEmailApi = new SendEmailApi(azureApimClient)
  const verifyReCaptchaApi = new VerifyReCaptchaApi(azureApimClient)
  const contactFormApi = new ContactFormApi(mppCloudClient)
  const leadGenerationApi = new LeadGenerationApi(azureApimClient)
  const otpApi = new OtpApi(mppCloudClient)
  const value = {
    sendEmailApi,
    verifyReCaptchaApi,
    contactFormApi,
    leadGenerationApi,
    otpApi,
  }
  return <ApiContext.Provider value={value}>{children}</ApiContext.Provider>
}

export { ApiProvider, ApiContext }
