import { AxiosInstance } from "axios"

export type ContactFormData = {
  firstName: string
  lastName: string
  from: string
  company: string
  phoneNumber?: string
  message: string
  formType: string
  howDidYouHearAboutUs: string
}

export type EmailInterestData = {
  from: string
  message?: string
}

export class ContactFormApi {
  private client: AxiosInstance

  constructor(client: AxiosInstance) {
    this.client = client
  }

  execute = (data: ContactFormData) =>
    this.client.post("shared/mbamails/api/mail/contact-form", data)

  executeEmailInterest = (data: EmailInterestData) =>
    this.client.post("shared/mbamails/api/mail/email-interest", data)
}
