import React from "react"
import { GatsbyBrowser } from "gatsby"
import { TagManagerContextProvider } from "../core/contexts/tagManager"
import { FirebaseContextProvider } from "../core/contexts/firebase"
import { ApiProvider } from "../core/contexts/api"
import { AuthUserContextProvider } from "../core/contexts/auth/user"

import "@fontsource/nunito"
import "../styles/globals.css"

const wrapRootElement: GatsbyBrowser["wrapRootElement"] = ({ element }) => {
  return (
    <TagManagerContextProvider>
      <FirebaseContextProvider>
        <AuthUserContextProvider>
          <ApiProvider>{element}</ApiProvider>
        </AuthUserContextProvider>
      </FirebaseContextProvider>
    </TagManagerContextProvider>
  )
}

export default wrapRootElement
